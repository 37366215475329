import React from 'react'
import * as ProgressPrimitive from '@radix-ui/react-progress'
import clsx from 'clsx'

import styles from './Progress.module.css'

export type ProgressProps = ProgressPrimitive.ProgressProps & {
  /**
   * The size of the progress bar
   * @default "medium"
   */
  size?: 'small' | 'medium' | 'large'
  /**
   * The visual style variant of the progress bar
   * @default "classic"
   */
  variant?: 'classic' | 'soft'
  /**
   * The color of the progress bar
   * @default "brand"
   */
  color?: 'brand' | 'critical' | 'warning' | 'success' | 'info'
  /**
   * Use the duration prop to indicate an approximate duration of an
   * indeterminate task. Once the duration times out, the progress bar will
   * remain at 100% until a value prop is added
   *
   * When an approximate duration can be estimated, the Progress component is
   * still useful over a spinner, which doesn’t provide any visual cues towards
   * the progress of the task.
   *
   * @default "5s"
   */
  duration?: `${number}s` | `${number}ms`
  /**
   * The current value of the progress bar
   * If undefined, the progress bar will be in an indeterminate state
   */
  value?: number
  /**
   * The maximum value of the progress bar
   * @default 100
   */
  max?: number
}

/**
 * Displays a progress bar related to a task.
 */
const Progress = React.forwardRef<HTMLDivElement, ProgressProps>(
  (
    {
      size = 'medium',
      variant = 'classic',
      color = 'brand',
      duration,
      value,
      max = 100,
      className,
      ...props
    }: ProgressProps,
    forwardedRef,
  ) => {
    const isIndeterminate = value === undefined

    return (
      <ProgressPrimitive.Root
        ref={forwardedRef}
        value={value}
        max={max}
        className={clsx(
          styles.root,
          styles[`size-${size}`],
          styles[`variant-${variant}`],
          styles[`color-${color}`],
          isIndeterminate && styles.indeterminate,
          className,
        )}
        style={
          {
            '--progress-duration': duration,
            '--progress-value': value,
            '--progress-max': max,
          } as React.CSSProperties
        }
        {...props}
      >
        <ProgressPrimitive.Indicator className={styles.indicator} />
      </ProgressPrimitive.Root>
    )
  },
)

Progress.displayName = 'Progress'

export { Progress }
